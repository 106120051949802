import React, { Fragment } from "react";
import { saveAs } from 'file-saver';
import { withStyles, withTheme } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Button,
  Divider,
  Avatar,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Dialog,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import customDate from "../../../../utils/customDate";
import numberSeparator from "../../../../utils/numberSeparator";
import {
  downloadLabel,
  updateOrder,
  getTracking,
} from "../../../../services/axios";
import swal from "sweetalert";
import GetAppIcon from "@material-ui/icons/GetApp";
import ImageIcon from '@material-ui/icons/Image';
import CheckIcon from "@material-ui/icons/Check";
import { ReactComponent as PremiumIcon } from "assets/icons/icon_premium.svg";
import CrossImg from "../../../../assets/images/cross.png";
import CheckImg from "../../../../assets/images/check.png";
import moment from "moment";
import WarningIcon from "@material-ui/icons/Warning";

class CardOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      payload: {
        resi: props.data.shipping.resi || "",
        is_canceled: 0,
        is_processed: 0,
        cancel_note: "",
      },
      text: "",
      isOpenDialogTracking: false,
      manifest: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.isLabelChecked !== nextProps.isLabelChecked ||
      this.props.data !== nextProps.data ||
      this.state !== nextState
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        data: this.props.data,
        payload: {
          resi: this.props.data.shipping.resi || "",
          is_processed: 0,
          is_canceled: 0,
          is_ready_to_delivery: 0,
          cancel_note: "",
        },
      });
    }
  }

  render() {
    const slug = JSON.parse(localStorage.getItem("DATA")).slug;
    const {
      classes,
      handleUbahPesanan,
      index,
      isLabelChecked,
      onLoading,
      onToggleDownloadQueue,
      theme,
    } = this.props;
    const { data, isOpenDialogTracking, manifest } = this.state;

    const getDay = (data) => {
      const date = new Date(data);
      const Month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      return `${date.getDate()} ${
        Month[date.getMonth()]
      } ${date.getFullYear()}`;
    };
    const last_history = data.status_history[data.status_history.length - 1];

    const handleCheckboxProsesPesanan = () => {
      this.setState({
        payload: {
          ...this.state.payload,
          is_processed: this.state.payload.is_processed === 0 ? 1 : 0,
          is_canceled: 0,
          is_ready_to_delivery: 0,
        },
      });
    };

    const handleCheckboxBatalPesanan = () => {
      this.setState({
        payload: {
          ...this.state.payload,
          is_processed: 0,
          is_ready_to_delivery: 0,
          is_canceled: this.state.payload.is_canceled === 0 ? 1 : 0,
        },
      });
    };

    const handleCheckboxSiapKirim = () => {
      this.setState({
        payload: {
          ...this.state.payload,
          is_processed: 0,
          is_canceled: 0,
          is_ready_to_delivery: this.state.payload.is_ready_to_delivery === 1 ? 0 : 1,
        },
      });
    };

    const handleUpdate = async () => {
      swal({
        title: "Apakah kamu yakin?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          const formData = new FormData();
          formData.append("_method", "PATCH");
          if (
            this.state.payload.is_canceled === 0 &&
            this.state.payload.is_processed === 0 &&
            this.state.payload.is_ready_to_delivery === 0
          ) {
            if (this.state.payload.resi.length !== 0) {
              formData.append("resi", this.state.payload.resi); 
            } else {
              return swal("Oops!", "Masukan Resi!", "warning");
            }
          } else if (
            this.state.payload.is_canceled === 1 &&
            this.state.payload.is_processed === 0 &&
            this.state.payload.is_ready_to_delivery === 0
          ) {
            formData.append("is_canceled", this.state.payload.is_canceled);
            formData.append("cancel_note", this.state.payload.cancel_note);

            if (!this.state.payload.cancel_note.length) {
              return swal("Oops!", "Masukan Catatan", "warning");
            }
          } else if (
            this.state.payload.is_canceled === 0 &&
            this.state.payload.is_processed === 1 &&
            this.state.payload.is_ready_to_delivery === 0
          ) {
            formData.append("is_processed", this.state.payload.is_processed);
          } else if (
            this.state.payload.is_canceled === 0 &&
            this.state.payload.is_processed === 0 &&
            this.state.payload.is_ready_to_delivery === 1
          ) {
            formData.append("is_ready_to_delivery", this.state.payload.is_ready_to_delivery);
          }
          if (
            this.state.payload.is_canceled !== 0 ||
            this.state.payload.is_processed !== 0 ||
            this.state.payload.resi.length !== 0 ||
            this.state.payload.is_ready_to_delivery !== 0
          ) {
            try {
              onLoading(true);
              const response = await updateOrder(slug, data.id, formData);
              if (response.updated) {
                swal("Success!", "Pesanan berhasil diupdate!", "success");
                this.setState({
                  data: response.data,
                  payload: {
                    resi: response.data.shipping.resi || "",
                    is_processed: 0,
                    is_canceled: 0,
                  },
                });
              }
              onLoading(false);
            } catch (error) {
              onLoading(false);
              if (error.response && error.response.data) {
                swal("Oops!", error.response.data.message, "warning");
              }
            }
          }
        }
      });
    };

    const getTotalSummary = (data) => {
      let returnData = 0;
      data.forEach((res) => {
        returnData = returnData + res.total_reseller_price_idr;
      });
      return returnData;
    };

    const actionChangeDownloadLabel = async (params) => {
      onLoading(true);
      const response = await downloadLabel(params.id);
      const url = window.URL.createObjectURL(
        new Blob([response], { type: "application/pdf" })
      );
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Order #${params.id}`);
      document.body.appendChild(link);
      link.click();
      this.setState({
        data: {
          ...this.state.data,
          is_label_downloaded: true,
        },
      });
      onLoading(false);
    };

    const handleDownload = (url, name) => {
      onLoading(true);
      const imageUrl = url;
      const ResellerName = name.replace(/\s/g, ''); 
      const imageExtension = imageUrl.split(".").pop().split(/\#|\?/)[0]; 
      console.log(`${ResellerName}.${imageExtension}`)
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, `${ResellerName}.${imageExtension}`);
        })
        .catch((error) => {
          console.error('Error downloading image:', error);
        });
      onLoading(false);
    };

    const actionDownloadImage = async (url) => {
      onLoading(true);
      const imageUrl = url;
      saveAs(imageUrl, "image.jpg")
      onLoading(false);
    };


    const handleDialogTracking = async (isOpen) => {
      this.setState({
        isOpenDialogTracking: isOpen,
      });

      if (isOpen) {
        const res = await getTracking({
          waybill: data.shipping.resi,
          courier: data.shipping.courier.code,
        });

        this.setState({
          manifest: res.manifest,
        });
      } else {
        this.setState({
          manifest: [],
        });
      }
    };

    return (
      <Fragment>
        <div className={classes.wrapperCard}>
          <div className={classes.flexBetween} style={{ paddingBottom: 10 }}>
            <div className={classes.flexAlignCenter}>
              <Typography variant="h6">
                <b>#{index}</b>
              </Typography>
              <div style={{ paddingLeft: 10 }}>
                <Typography variant="body2">
                  Card Order ID <b>#{data.id}</b>
                </Typography>
                <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                  {customDate(new Date(data.created_at))}
                </Typography>
              </div>
            </div>

            <div
              className={classes.flexAlignCenter}
              style={{
                cursor:
                  new RegExp("batal").test(
                    data.status_history[data.status_history.length - 1]?.message
                  ) && "no-drop",
              }}
            >
              {data.reseller.storefront.avatar_url && (
              <Button
                style={{marginRight: 10}}
                className={classes.downloadLabel}
                onClick={() => actionDownloadImage(data.reseller.storefront.avatar_url)}
                variant="outlined"
              >
                <ImageIcon />
                Open Image Storefront
              </Button>
              )}
              <Checkbox
                checked={isLabelChecked}
                onChange={() => onToggleDownloadQueue(data.id)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <Button
                style={{ marginRight: 10 }}
                className={classes.downloadLabel}
                // onClick={() => actionChangeDownloadLabel(data)}
                href={"https://api.idejualan.com/printlabel/preview/" + data.id}
                variant="outlined"
              >
                {this.state.data.is_label_downloaded ? (
                  <CheckIcon />
                ) : (
                  <GetAppIcon />
                )}
                Download Label
              </Button>
              <Button
                className={classes.ubahPesanan}
                onClick={() => handleUbahPesanan(data)}
                disabled={
                  new RegExp("batal").test(
                    data.status_history[data.status_history.length - 1]?.message
                  )
                    ? true
                    : false
                }
              >
                <EditIcon className={classes.EditIcon} />
                Ubah Pesanan
              </Button>
            </div>
          </div>

          <Divider className={classes.Divider} />

          {data.automatic_cancel_at && (
            <Box
              my={3}
              p={2}
              display="flex"
              alignItems="center"
              style={{ backgroundColor: "wheat" }}
            >
              <Box mr={1}>
                <WarningIcon style={{ fill: "red", fontSize: 40 }} />
              </Box>
              <Box>
                <b>PENTING : </b>{" "}
                {last_history.status === 35 && (
                  <span>
                    Mohon kirim sebelum tanggal{" "}
                    <b>
                      {moment(data.automatic_cancel_at).format("DD MMMM YYYY")}
                    </b>
                    , jika melebihi tanggal yang di tentukan, maka pesanan akan
                    otomatis dibatalkan
                  </span>
                )}
                {last_history.status === 20 && (
                  <span>
                    Mohon diproses sebelum tanggal{" "}
                    <b>
                      {moment(data.automatic_cancel_at).format("DD MMMM YYYY")}
                    </b>
                    , jika melebihi tanggal yang di tentukan, maka pesanan akan
                    otomatis dibatalkan
                  </span>
                )}
              </Box>
            </Box>
          )}

          <div style={{ padding: "10px 0" }} className={classes.flexBetween}>
            <div style={{ width: 250 }}>
              <Typography>BILLING INFO</Typography>
              <div style={{ paddingTop: 10 }}>
                <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                  Pemesan
                </Typography>
                <div
                  className={classes.flexAlignCenter}
                  style={{ paddingTop: 5 }}
                >
                  <Avatar
                    alt="reseller"
                    src={data.reseller.avatar}
                    className={classes.large}
                  />
                  {data.reseller ? (
                    <Grid container alignItems="center">
                      <Typography variant="body2" style={{ paddingLeft: 10 }}>
                        <span style={{ marginRight: 5 }}>
                          {data.reseller.name}
                        </span>
                      </Typography>
                      {data.reseller.activated && (
                        <PremiumIcon
                          width="20"
                          fill={theme.palette.yellow.primary}
                        />
                      )}
                    </Grid>
                  ) : (
                    "Tidak ada data"
                  )}
                </div>
              </div>
              <div style={{ paddingTop: 10 }}>
                <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                  Dikirim Kepada
                </Typography>
                <Typography variant="body2">
                  {data.customer ? data.customer.name : "Tidak ada data"}
                </Typography>
                {data.customer && (
                  <>
                  <Typography
                    variant="caption"
                    style={{ lineHeight: "initial" }}
                  >
                    {data.customer.address + ", "}
                    {data.customer.subdistrict &&
                      data.customer.subdistrict?.name +
                      ", " +
                      data.customer.subdistrict?.city?.name +
                      ", " +
                      data.customer.subdistrict?.province?.name}
                  </Typography>
                  <br />
                    <Typography variant="caption"
                    style={{ lineHeight: "initial" }}
                    >
                      {data.customer ? data.customer.phone : "Tidak ada data"}
                    </Typography>
                    </>
                )}
              </div>
            </div>

            <div style={{ padding: "0 10px", width: 200 }}>
              <div style={{ padding: 4, borderRadius: "4px", border: data.is_maklon ? "2px solid red" : "none"}}>
                <Typography>NOTES</Typography>
                <Typography variant="body2">
                  {data.customer_note ? data.customer_note : "-"}
                </Typography>
              </div>
              <div>
                <Grid container style={{ marginTop: 30 }}>
                  <Grid item>
                    {(last_history.status === 10 ||
                      last_history.status === 20) && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.payload.is_canceled === 1}
                              onClick={handleCheckboxBatalPesanan}
                              color="primary"
                            />
                          }
                          label="Batalkan Pesanan"
                          labelPlacement="start"
                        />
                        <div>
                          <TextField
                            variant="outlined"
                            placeholder="Masukan catatan"
                            value={this.state.payload.cancel_note}
                            onChange={(e) =>
                              this.setState({
                                payload: {
                                  ...this.state.payload,
                                  cancel_note: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item>
                    {last_history.status === 20 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.payload.is_processed === 1}
                            onClick={handleCheckboxProsesPesanan}
                            color="primary"
                          />
                        }
                        label="Proses Pesanan"
                        labelPlacement="start"
                      />
                    )}
                    {last_history.status === 30 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.payload.is_ready_to_delivery === 1}
                            onClick={handleCheckboxSiapKirim}
                            color="primary"
                          />
                        }
                        label="Siap Kirim"
                        labelPlacement="start"
                      />
                    )}
                  </Grid>
                </Grid>

                {(last_history.status === 45 || last_history.status === 50) && (
                  <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs={12}>
                      <Typography variant="body2" style={{ paddingLeft: 10 }}>
                        Resi
                      </Typography>
                      <TextField
                        className={classes.inputStyle}
                        InputProps={{ disableUnderline: true }}
                        value={this.state.payload.resi}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                )}

                {(last_history.status === 35 || last_history.status === 40) && (
                  <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs={12}>
                      <Typography variant="body2" style={{ paddingLeft: 10 }}>
                        Input Resi
                      </Typography>
                      <TextField
                        className={classes.inputStyle}
                        InputProps={{ disableUnderline: true }}
                        value={this.state.payload.resi}
                        onChange={(e) => {
                          const value = e.target.value;
                          this.setState({
                            payload: { ...this.state.payload, resi: value },
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {last_history.status >= 10 && last_history.status <= 40 && (
                  <div className={classes.updateButtonDiv}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.updateButton}
                      onClick={handleUpdate}
                    >
                      {last_history.status === 35 || last_history.status === 40
                        ? last_history.status === 35
                          ? "Input Resi"
                          : "Update Resi"
                        : "Update Status Order"}
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div>
              <Typography className={classes.flexEnd}>ORDER SUMMARY</Typography>
              {data.products.map((res, index) => {
                let productSKU = null;
                let productBundling = null;

                if (res.sku && res.sku !== null) {
                  productSKU = (
                    <div
                      key={res.id}
                      variant="body2"
                      style={{
                        color: "#a2a2a2",
                        paddingTop: 10,
                        display: "flex",
                        justifyContent: "flex-end",
                        fontWeight: "bold",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Avatar
                          alt="Produk"
                          src={res.sku.image_url}
                          className={classes.large}
                          style={{ marginRight: 10 }}
                        />
                        <div style={{ textAlign: "right" }}>
                          <span>
                            {res.sku.product.name.length > 43
                              ? res.sku.product.name.slice(0, 43) + "..."
                              : res.sku.product.name}
                          </span>
                          <Typography style={{ width: 300 }}>
                            ( {res.sku.properties.material},
                            {res.sku.properties.color},{res.sku.properties.size}{" "}
                            )
                          </Typography>
                        </div>
                      </div>
                      <span style={{ paddingLeft: 25 }}>X{res.qty}</span>
                      <span style={{ paddingLeft: 25 }}>
                        {numberSeparator(res.reseller_price_idr)}
                        {data.is_cod ? (
                          <span>
                            <br></br>(COD Price :{" "}
                            {numberSeparator(res.consumer_price_idr)})
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  );
                }

                if (res.bundling && res.bundling !== null) {
                  productBundling = res.bundling.items.map((bundling) => (
                    <div
                      key={res.id + bundling.id}
                      variant="body2"
                      style={{
                        color: "#a2a2a2",
                        paddingTop: 10,
                        display: "flex",
                        justifyContent: "flex-end",
                        fontWeight: "bold",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Avatar
                          alt="Produk"
                          src={bundling.sku.image}
                          className={classes.large}
                          style={{ marginRight: 10 }}
                        />
                        <div style={{ textAlign: "right" }}>
                          <span>
                            {bundling.name.length > 43
                              ? bundling.name.slice(0, 43) + "..."
                              : bundling.name}
                          </span>
                          <Typography style={{ width: 300 }}>
                            ( {bundling.sku.properties.material},
                            {bundling.sku.properties.color},
                            {bundling.sku.properties.size} )
                          </Typography>
                        </div>
                      </div>
                      <span style={{ paddingLeft: 25 }}>X{res.qty}</span>
                      <span style={{ paddingLeft: 25 }}>
                        {numberSeparator(bundling.reseller_price_idr)}
                      </span>
                    </div>
                  ));
                }

                return (
                  <Fragment key={res.id}>
                    {productSKU}
                    {productBundling}
                  </Fragment>
                );
              })}
              <Typography
                className={classes.flexEnd}
                style={{ paddingTop: 10 }}
              >
                Total
                <b style={{ paddingLeft: 50 }}>
                  Rp. {numberSeparator(getTotalSummary(data.products))}
                </b>
              </Typography>
              <Typography className={classes.flexEnd}>
                Perkiraan Ongkir :{" "}
                <b>Rp. {numberSeparator(data.shipping.cost)}</b>
              </Typography>
            </div>
          </div>

          <Divider className={classes.Divider} />

          <div style={{ padding: "10px 0" }} className={classes.flexBetween}>
            <div>
              <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                Status Pesanan
              </Typography>
              <div style={{ paddingTop: 10 }}>
                <div className={classes.stepperWrapper}>
                  {[1, 2, 3, 4, 5].map((res, index) => (
                    <Fragment key={res}>
                      <div
                        className={classes.roundStepper}
                        style={{
                          backgroundColor:
                            !data.status_history[index] && "#c4c4c4",
                        }}
                      />
                      {index !== 4 && (
                        <div
                          className={classes.stepper}
                          style={{
                            backgroundColor:
                              !data.status_history[index + 1] && "#c4c4c4",
                          }}
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
                <div style={{ display: "flex" }}>
                  {data.status_history.map((res, index) => (
                    <div style={{ width: 105 }} key={res.id}>
                      <Typography variant="caption">{res.message}</Typography>
                      <Typography
                        variant="caption"
                        style={{ color: "#a2a2a2", display: "block" }}
                      >
                        {getDay(res.timestamp)}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                Kurir
              </Typography>
              <div style={{ paddingTop: 10 }}>
                <img
                  src={data.shipping.courier.icon_url}
                  alt=""
                  height={50}
                  width={50}
                />
              </div>
              <div>
                {data.shipping.courier.name}
                <br />
                {data.shipping.courier_type &&
                  "Layanan : " + data.shipping.courier_type}
                <br />
                {data.shipping.estimated && "Est : " + data.shipping.estimated}
              </div>
            </div>
            <div>
              {" "}
              <Grid item>
                <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                  COD
                </Typography>
                <img
                  alt={data.is_cod ? "COD" : "NON COD"}
                  width={50}
                  src={
                    data.is_cod
                      ? CheckImg
                      : CrossImg
                  }
                  className={classes.large}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ paddingTop: 10, textTransform: "capitalize" }}
                >
                  {data.is_cod ? "Order COD" : "Tidak Menggunakan COD"}
                </Typography>
              </Grid>
            </div>
            <div>
              <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                Tipe pembayaran
              </Typography>
              <Typography
                variant="body2"
                style={{ paddingTop: 10, textTransform: "capitalize" }}
              >
                {data.payment && data.payment.payment_type
                  ? data.payment.payment_type.replace("_", " ")
                  : "Tidak ada data"}
              </Typography>
              <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                Jumlah Koin
              </Typography>
              <Typography
                variant="body2"
                style={{ paddingTop: 10, textTransform: "capitalize" }}
              >
                {data.payment && data.payment.coin
                  ? data.payment.coin
                  : "Tidak ada koin digunakan"}
              </Typography>
              <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                Jumlah Koin
              </Typography>
              <Typography
                variant="body2"
                style={{ paddingTop: 10, textTransform: "capitalize" }}
              >
                {data.payment && data.payment.coin
                  ? data.payment.coin
                  : "Tidak ada koin digunakan"}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                Catatan pembatalan
              </Typography>
              <Typography
                variant="body2"
                style={{ paddingTop: 10, textTransform: "capitalize" }}
              >
                {data.cancel_note || "-"}
              </Typography>
            </div>
            <Grid item>
              <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                Dropship
              </Typography>
              <img
                alt="MP"
                width={50}
                src={
                  data.is_dropship
                    ? data.marketplace.icon
                    : "https://i.pinimg.com/736x/4f/11/e6/4f11e623f48c7cb463ea2f60deea1e40.jpg"
                }
                className={classes.large}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              />
              <Typography
                variant="body2"
                style={{ paddingTop: 10, textTransform: "capitalize" }}
              >
                {data.is_dropship
                  ? data.marketplace.code
                  : "Tidak Pakai Dropship"}
              </Typography>
            </Grid>
            {/* maklon */}
            <div>
              {" "}
              <Grid item>
                <Typography variant="body2" style={{ color: "#a2a2a2" }}>
                  Maklon
                </Typography>
                <img
                  alt={data.is_maklon ? "Maklon" : "NON Maklon"}
                  width={50}
                  src={
                    data.is_maklon
                      ? CheckImg
                      : CrossImg
                  }
                  className={classes.large}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ paddingTop: 10, textTransform: "capitalize" }}
                >
                  {data.is_maklon ? "Maklon" : "Tidak Maklon"}
                </Typography>
              </Grid>
            </div>
          </div>
          {last_history.status === 40 && (
            <Box mt={2}>
              <Button
                onClick={() => handleDialogTracking(true)}
                size="small"
                variant="contained"
                color="primary"
              >
                Lacak
              </Button>
            </Box>
          )}
        </div>
        <Dialog
          open={isOpenDialogTracking}
          onClose={() => handleDialogTracking(false)}
          fullWidth
          maxWidth="sm"
        >
          <Box p={4}>
            {manifest.length > 0 ? (
              <Stepper orientation="vertical">
                {manifest.map((step) => (
                  <Step key={step.manifest_code} active>
                    <StepLabel>{step.city_name}</StepLabel>
                    <StepContent>
                      <Typography variant="caption">
                        {step.manifest_date} : {step.manifest_time}
                      </Typography>
                      <Typography>{step.manifest_description}</Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            ) : (
              "Resi tidak valid"
            )}
          </Box>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(style)(withTheme(CardOrder));
